import React, {RefObject, createRef, useEffect, useState} from 'react';
import './EnterPin.css';
import { AppTools, MessageViewType } from '../../types.ts';
import { ATTEMPT_CONNECT_ROUTE } from '../../route.ts';
import { ApiError } from '../../api/responses.ts';

type EnterPinProps = {
    isNewPin?: boolean,
    tryConnect: (pin: string) => Promise<boolean>,
    cancelConnect: () => void,
    appTools: AppTools
};

const EnterPin = (props: EnterPinProps) => {
    const { tryConnect, cancelConnect, appTools } = props;
    const [isValid, setIsValid] = useState<boolean>(false);

    const input1 = createRef<HTMLInputElement>();
    const input2 = createRef<HTMLInputElement>();
    const input3 = createRef<HTMLInputElement>();
    const input4 = createRef<HTMLInputElement>();

    const onChangeText = (
        inputActual: RefObject<HTMLInputElement>, 
        nextInput?: RefObject<HTMLInputElement>) => {
        let newText = inputActual.current!.value;
        if(newText.length === 0){
            // l'utilisateur effectue un retour
            inputActual.current!.value = "";
        }
        else if(newText.length === 1){
            // l'utilisateur écrit un chiffre
            inputActual.current!.value = newText;
            if(nextInput){
                nextInput.current!.focus();
            }else{
                inputActual.current!.blur();
            }
        }else {
            inputActual.current!.value = newText[0];
        }
        checkIsValid();
    };

    const detectReturn = (
        event: React.KeyboardEvent<HTMLInputElement>,
        inputActual: RefObject<HTMLInputElement>, 
        beforeInput: RefObject<HTMLInputElement>) => {
        const key: string = event.key;

        if(key === "Backspace" || key === "Delete"){
            if(inputActual.current!.value === ""){
                beforeInput.current!.focus();
                beforeInput.current!.select();
            }
        }
    }

    const getPinCode = (): string => {
        return input1.current!.value + 
        input2.current!.value + 
        input3.current!.value + 
        input4.current!.value;
    }

    const checkIsValid = () => {
        const pin: string = getPinCode();
        if(isValid && pin.length !== 4){
            setIsValid(false);
        }
        else if(!isValid && pin.length === 4){
            setIsValid(true);
            triggerTryConnect();
        }
    }

    const triggerTryConnect = () => {
        appTools.isLoading(true);
        tryConnect(getPinCode())
        .then((result) => {
            if(!result){
                appTools.showMessage(MessageViewType.Error, "QRCode ou code PIN Incorrect")
            }
        })
        .catch((error: ApiError) => {
            appTools.showMessage(MessageViewType.Error, error.message);
        })
        .finally(() => {
            appTools.isLoading(false);
        });
    }

    const triggerCancelConnect = () => {
        // on supprime le contenu de l'url si l'utilisateur
        // s'est connecté grâce à un QRCode
        // (si on ne fait pas ça, l'utilisateur sera redirigé vers cette page sans fin)
        if(window.location.href.includes(ATTEMPT_CONNECT_ROUTE)){
            window.location.href = "/";
        }

        cancelConnect();
    }

    return(
        <div className="pin-main fill-to-screen">
            <h1 className='pin-title'>Bienvenue</h1>
            <p className='pin-subtitle'></p>
            <div className='pin-container'>
                <p className='pin-text'>Entrer le code PIN</p>
                <div className='pin-separator' />
                <div className='pin-input-container'>
                    <input autoFocus type="password" pattern="[0-9]*" inputMode="numeric" ref={input1} onInput={() => { onChangeText(input1, input2)}} className='pin-input' />
                    <input type="password" pattern="[0-9]*" inputMode="numeric" ref={input2} onKeyUp={(event) => detectReturn(event, input2, input1)} onInput={() => { onChangeText(input2, input3)}} className='pin-input' />
                    <input type="password" pattern="[0-9]*" inputMode="numeric" ref={input3} onKeyUp={(event) => detectReturn(event, input3, input2)} onInput={() => { onChangeText(input3, input4)}} className='pin-input' />
                    <input type="password" pattern="[0-9]*" inputMode="numeric" ref={input4} onKeyUp={(event) => detectReturn(event, input4, input3)} onInput={() => { onChangeText(input4, undefined)}} className='pin-input' />
                </div>
            </div>
            <button disabled={!isValid} onClick={() => {triggerTryConnect()}} className='white-button pin-validate-button'>Valider</button>
            <button onClick={() => {triggerCancelConnect()}} className='white-button pin-cancel-button'>Annuler</button>
        </div>
    );
}

export default EnterPin;